import React from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import HeroImage from "../components/HeroImage/heroImage"
import image from "../assets/images/loading.jpg"

const BlogChildPage = () => (
  <Layout>
    <SEO title="Blog" />
    <HeroImage title="Why we're specialist In On Site Welding" subtitle="20th July" image={image} position="center bottom" padding="150px 0" />
    <main>
      <div className="blog_content">
        <div className="article-width">
          <p className="lead-paragraph">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
          <p>Lorem Ipsum is simply dummy text. <a href="/">This is a link</a> of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
          <div className="blog_content__img">
            <img src="https://cdn.pixabay.com/photo/2015/03/14/18/09/welder-673559__340.jpg" alt="alt text" />
            <span>A caption to go with the image.</span>
          </div>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        </div>
      </div>
    </main>
  </Layout>
)

export default BlogChildPage
